var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"dialog-container",attrs:{"tile":""}},[_c('v-container',{staticClass:"d-flex flex-column align-center ma-0 pa-0",class:{
      'justify-space-between': !_vm.loading && !_vm.catastrophicError,
      'justify-center': _vm.loading || _vm.catastrophicError,
    },staticStyle:{"height":"100%","min-height":"200px"},attrs:{"fluid":""}},[_c('div',{staticClass:"px-4",staticStyle:{"width":"100%"}},[_c('v-row',{staticClass:"ma-0 d-flex",attrs:{"dense":""}},[_c('div',{staticClass:"d-flex justify-space- w-full"},[_c('v-col',{attrs:{"cols":"9"}},[_c('div',{staticClass:"d-flex"},[_c('h2',[_vm._v("Historial de créditos")]),_c('v-tooltip',{attrs:{"top":"","max-width":300},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-md-6 ml-2 color-gray-text",attrs:{"medium":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiInformationOutline)+" ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.tooltipCredit))])])],1)]),_c('v-col',{staticClass:"d-flex flex-row justify-end",attrs:{"cols":"3"}},[_c('v-btn',{attrs:{"icon":"","disabled":_vm.loadingTable},on:{"click":_vm.handleClose}},[_c('v-icon',{attrs:{"color":"primary","large":""}},[_vm._v(_vm._s(_vm.icons.mdiClose))])],1)],1)],1)]),_c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-divider')],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"outlined":"","color":"primary","disabled":_vm.loadingTable},on:{"click":function($event){return _vm.handleExport()}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiDownload))]),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.ExportMessage))])],1)],1)],1),_c('v-row',{staticClass:"ma-0 mt-2"},[_c('v-col',{staticClass:"px-1",attrs:{"cols":"12"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"dataTable elevation-1",attrs:{"loading":_vm.loadingTable,"headers":_vm.table.headers,"disable-sort":true,"items":_vm.table.items,"calculate-widths":"","hide-default-footer":"","disable-pagination":"","fixed-header":"","mobile-breakpoint":"0","dense":true},scopedSlots:_vm._u([{key:"header.amount_added",fn:function(ref){
    var header = ref.header;
return [_c('div',{staticClass:"d-flex justify-center"},[_vm._v(" "+_vm._s(header.text)+" "),_c('v-tooltip',{attrs:{"top":"","max-width":300},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-md-6 ml-2 color-gray-text",attrs:{"medium":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiInformationOutline)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.tooltipAmountAdded))])])],1)]}},{key:"item.amount_added",fn:function(ref){
    var value = ref.value;
return [(value !== '-')?_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(value))+" ")]):_c('span',[_vm._v(_vm._s(value))])]}},{key:"item.amount_paid",fn:function(ref){
    var value = ref.value;
return [(value !== '-')?_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(value))+" ")]):_c('span',[_vm._v(_vm._s(value))])]}},{key:"item.status",fn:function(ref){
    var value = ref.value;
return [_c('v-chip',{attrs:{"color":_vm.getStatusColor(value),"text-color":"white","small":""}},[_vm._v(" "+_vm._s(_vm.getStatusText(value))+" ")])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-menu',{attrs:{"rounded":"","offset-y":"","disabled":_vm.loading,"nudge-left":"50"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var attrs = ref.attrs;
    var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical)+" ")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"id":"btn-action-view-credit-details","link":""}},[_c('p',{on:{"click":function($event){return _vm.handleViewDetail(item)}}},[_vm._v("Ver detalles")])])],1)],1)]}}])})],1)],1),_c('v-row',{staticClass:"d-flex flex-row justify-space-between align-center",attrs:{"dense":""}},[_c('v-col',{staticClass:"d-flex flex-row justify-end align-center pt-8",attrs:{"cols":"12","md":"4"}},[_c('v-select',{attrs:{"label":_vm.resultPerPage,"items":[5, 10, 20, 30],"disabled":_vm.table.tableLoading,"dense":"","outlined":""},on:{"input":function($event){return _vm.getCorporateCredits()}},model:{value:(_vm.table.limit),callback:function ($$v) {_vm.$set(_vm.table, "limit", $$v)},expression:"table.limit"}}),_c('v-autocomplete',{class:!_vm.$vuetify.mdAndUp && 'ml-2',attrs:{"label":_vm.goToPage,"items":Array.from(Array(_vm.table.pages + 1).keys()).slice(1),"disabled":_vm.table.tableLoading,"dense":"","outlined":""},on:{"input":function($event){_vm.table.currentPage && _vm.getCorporateCredits()}},model:{value:(_vm.table.currentPage),callback:function ($$v) {_vm.$set(_vm.table, "currentPage", $$v)},expression:"table.currentPage"}})],1),_c('v-col',{staticClass:"\n                d-flex\n                flex-row\n                justify-md-end justify-center\n                align-start\n              ",attrs:{"cols":"12","md":"6"}},[_c('v-pagination',{attrs:{"length":_vm.table.pages,"total-visible":"12","disabled":_vm.table.tableLoading},on:{"input":function($event){return _vm.getCorporateCredits()}},model:{value:(_vm.table.currentPage),callback:function ($$v) {_vm.$set(_vm.table, "currentPage", $$v)},expression:"table.currentPage"}})],1)],1)],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }