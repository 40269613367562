<template>
  <PaymentStepper
    :steps="steps"
    title="Zelle"
    @close="close"
    @closePaymentDrawer="$emit('closePaymentDrawer')"
    @refresh="$emit('refresh')"
  >
    <template v-slot:image>
      <v-img class="mr-2" :src="svgIcon" />
    </template>
  </PaymentStepper>
</template>

<script>
import PaymentStepper from "../PaymentStepper.vue";
import ZelleStep1 from "./ZelleStep1.vue";
import ResultStep from "../ResultStep.vue";

export default {
  components: {
    PaymentStepper,
  },
  props: {
    credit_amount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      paymentData: {
        amount: this.credit_amount,
        success: false,
        PaymentTitle: "Zelle",
        dataStep: 1,
      },
      steps: [],
      svgIcon: require("../../../assets/payment-methods/zelle.svg"),
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
  created() {
    this.steps = [
      {
        component: ZelleStep1,
        data: this.paymentData,
      },
      {
        component: ResultStep,
        data: this.paymentData,
      },
    ];
  },
  mounted() {
    this.$emit("setStep", 1);
  },
};
</script>
