import { render, staticRenderFns } from "./ManualPaymentStep3.vue?vue&type=template&id=0cff0e28&scoped=true&"
import script from "./ManualPaymentStep3.vue?vue&type=script&lang=js&"
export * from "./ManualPaymentStep3.vue?vue&type=script&lang=js&"
import style0 from "./ManualPaymentStep3.vue?vue&type=style&index=0&id=0cff0e28&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cff0e28",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCard,VCol,VDivider,VFileInput,VRadio,VRadioGroup,VRow,VTextField})
