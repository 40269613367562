<template>
  <v-row class="ma-0 pa-0 d-flex flex-column">
    <template v-if="!loadingCarrousel">
      <v-row dense class="ma-0 pa-0 d-flex flex-column w-full">
        <v-col
          cols="12"
          class="pl-2 d-flex flex-column w-full"
          v-for="(payment, index) in payments"
          :key="payment.index"
        >
          <div class="hover-effect">
            <div
              class="d-flex flex-row w-full py-2"
              @click="handleMethodClick(payment)"
            >
              <v-img
                class="mr-4"
                :src="handlePaymentMethodAvatar(payment)"
                :max-width="$vuetify.breakpoint.mdAndUp ? 40 : 30"
                :max-height="$vuetify.breakpoint.mdAndUp ? 40 : 30"
                contain
              />
              <div class="d-flex flex-column w-full">
                <div
                  class="d-flex flex-row font-weight-bold text--primary mt-1"
                  style="width: 100%"
                >
                  {{ payment.name }}
                </div>
                <div class="text-xs w-full">
                  <span>
                    {{
                      payment.type === "automated" ? "Automatizado" : "Manual"
                    }}
                  </span>
                </div>
              </div>
            </div>
            <v-divider v-if="index < payments.length - 1" />
          </div>
        </v-col>
        <template v-if="payments.length === 0 && payments.length === 0">
          <v-col cols="12" class="d-flex flex-row justify-center">
            No hay opciones de recarga disponibles
          </v-col>
        </template>
      </v-row>
      <br />
      <!-- <v-row dense class="ma-0 pa-0 mb-3" v-if="false">
        <v-col
          cols="12"
          class="
            d-flex
            flex-column flex-md-row
            justify-md-end justify-center
            align-center
          "
        >
          <span class="mr-2"> Opciones: </span>
          <v-chip
            v-for="(filterChip, index) in filters"
            :key="filterChip.index"
            :outlined="!filterChip.active"
            :class="{
              'ml-md-2': index > 0,
              'mt-2': !$vuetify.breakpoint.mdAndUp,
            }"
            @click="filterChip.active = !filterChip.active"
            class="d-flex flex-row justify-center align-center"
            color="primary"
          >
            <span class="">
              <v-simple-checkbox
                v-model="filterChip.active"
                :dark="filterChip.active"
              ></v-simple-checkbox>
            </span>
            {{ ES ? filterChip.nameES : filterChip.nameEN }}
          </v-chip>
        </v-col>
      </v-row> -->
      <!-- <v-divider></v-divider>
      <v-row
        dense
        class="ma-0 pa-0 d-flex flex-row justify-center justify-md-end mt-3"
      >
        <span class="font-weight-bold"> Nota: </span>
        <span class="pl-2">
          Las opciones de
          <span class="font-weight-bold"> recarga manual </span> deben ser
          validadas por un administrador
        </span>
      </v-row> -->
    </template>
    <template v-if="loadingCarrousel">
      <v-col class="d-flex flex-column justify-end align-center">
        <v-skeleton-loader
          v-for="index in 6"
          :key="index"
          class="mb-4"
          height="70"
          width="100%"
          type="card"
        ></v-skeleton-loader>
      </v-col>
    </template>
    <div v-for="payment in payments" :key="payment._id">
      <v-navigation-drawer
        v-if="showPaymentModal[payment._id]"
        v-model="showPaymentModal[payment._id]"
        fixed
        :permantent="showPaymentModal[payment._id]"
        right
        :width="drawerWidth"
        style="height: 100dvh"
        class="drawer"
      >
        <v-container fluid class="ma-0 pa-0">
          <v-card flat class="ma-0 pa-0">
            <v-row>
              <v-col class="my-0 pb-0" style="height: 100vh">
                <ZellePayment
                  v-if="
                    showPaymentModal[payment._id] && payment._id === 'usd-zelle'
                  "
                  @closePaymentDrawer="handleClosePaymentDrawer(payment)"
                  @close="handleClose(payment)"
                  @refresh="$emit('refreshPayments')"
                  ref="zellePayment"
                  :is_credit_pay="is_credit_pay"
                  :credit_amount="credit_amount"
                />
                <PagoMovilPayment
                  v-else-if="
                    showPaymentModal[payment._id] &&
                    payment._id === 'bs-pago-movil'
                  "
                  @refresh="
                    () => {
                      $emit('refreshPayments');
                      if (is_credit_pay) {
                        $emit('refreshCredits');
                      }
                    }
                  "
                  @closePaymentDrawer="handleClosePaymentDrawer(payment)"
                  @close="handleClose(payment)"
                  :is_credit_pay="is_credit_pay"
                  :credit_amount="credit_amount"
                />
                <ManualPayment
                  v-else-if="showPaymentModal[payment._id]"
                  @closePaymentDrawer="handleClosePaymentDrawer(payment)"
                  @close="handleClose(payment)"
                  @refresh="$emit('refreshManualPayments')"
                  :data="{
                    ...payment,
                    is_credit_pay: is_credit_pay,
                    credit_amount: credit_amount,
                  }"
                />
              </v-col>
            </v-row>
          </v-card>
        </v-container>
      </v-navigation-drawer>
    </div>
  </v-row>
</template>
<script>
import axios from "@/plugins/axios";
import ManualPayment from "./recharge/manualPayment/ManualPayment.vue";
import ZellePayment from "./recharge/zelle/ZellePayment.vue";
import PagoMovilPayment from "./recharge/pagoMovil/PagoMovilPayment.vue";

export default {
  name: "TopUps",
  components: {
    ManualPayment,
    ZellePayment,
    PagoMovilPayment,
  },
  props: {
    is_credit_pay: {
      type: Boolean,
      default: false,
    },
    credit_amount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      payments: [
        {
          _id: "bs-pago-movil",
          name: "Pago móvil",
          currency: "bs",
          type: "automated",
          svgIcon: require("../assets/payment-methods/pago-movil.svg"),
        },
        // {
        //   _id: "usd-zelle",
        //   name: "Zelle",
        //   currency: "usd",
        //   type: "automated",
        //   svgIcon: require("../assets/payment-methods/zelle.svg"),
        // },
      ],
      loadingCarrousel: false,
      filters: [
        {
          nameES: "Automatizado",
          nameEN: "Automated",
          active: true,
          id: "automated",
        },
        {
          nameES: "Manual",
          nameEN: "Manual",
          active: true,
          id: "manual",
        },
        {
          nameES: "En Bs",
          nameEN: "In Bs",
          active: true,
          id: "bs",
        },
        {
          nameES: "En USD",
          nameEN: "In USD",
          active: true,
          id: "usd",
        },
      ],
      showPaymentModal: {},
    };
  },
  methods: {
    async getManualPaymentMethods() {
      this.loadingCarrousel = true;
      try {
        const manualPaymentMethodsResponse = await axios.get(
          "/get_payments_manual"
        );
        if (!manualPaymentMethodsResponse?.data?.success) {
          throw new Error("Error getting ManualPaymentMethods");
        }

        const { payments = [] } = manualPaymentMethodsResponse.data;
        this.populatePaymentMethods(payments);
      } catch (error) {
        console.log(
          "🚀 ~ file: TopUps.vue:61 ~ getManualPaymentMethods ~ error:",
          error
        );
        this.$dialog.notify.error(error.message);
      } finally {
        this.loadingCarrousel = false;
      }
    },
    populatePaymentMethods(manualPayments = []) {
      manualPayments = manualPayments.sort(
        (a, b) => Number(b.currency_code) - Number(a.currency_code)
      );
      manualPayments.forEach((payment) => {
        this.showPaymentModal[payment._id] = false;
        if (payment.is_available) {
          const {
            _id,
            name,
            icon_file_url = "",
            currency_code,
            detail = "",
            unique_id,
          } = payment;

          let payload = {
            _id,
            unique_id,
            name,
            currency: currency_code === "2" ? "bs" : "usd",
            type: "manual",
            svgIcon: icon_file_url,
            detail,
          };

          this.payments.push(payload);
        }
      });
    },
    handlePaymentMethodAvatar(paymentMethod) {
      if (!Boolean(paymentMethod.svgIcon)) {
        const TYPE_MAPPPING = {
          bs: require("../assets/payment-methods/base-bs.svg"),
          usd: require("../assets/payment-methods/currency-usd.svg"),
        };
        return TYPE_MAPPPING[paymentMethod.currency] || "";
      }
      return paymentMethod.svgIcon;
    },
    handleMethodClick(paymentMethod) {
      this.showPaymentModal = {
        ...this.showPaymentModal,
        [paymentMethod._id]: true,
      };
    },
    handleClose(payment) {
      this.$emit("closeTopUpsDrawer");
      this.showPaymentModal[payment._id] = false;
    },
    handleClosePaymentDrawer(payment) {
      this.showPaymentModal[payment._id] = false;
    },
  },
  computed: {
    ES() {
      return this.$vuetify.lang.current === "es";
    },
    drawerWidth() {
      return this.$vuetify.breakpoint.xsOnly ? "100%" : "580px";
    },
  },
  async created() {
    this.getManualPaymentMethods();
  },
};
</script>
<style lang="scss" scoped>
.payment-method {
  width: 100px;
  height: 100px;
  cursor: pointer;
  &-avatar {
    min-width: 60px;
    min-height: 60px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
  &-currency {
    font-size: 10px;
  }
  &-name {
    font-size: 10px;
    text-align: center;
  }
}

// media queries

/* Extra small (xs) */
@media (max-width: 599px) {
  .payment-method {
    width: 100px;
    height: 100px;
    cursor: pointer;
    &-avatar {
      min-width: 50px;
      min-height: 50px;
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
    &-currency {
      font-size: 10px;
    }
    &-name {
      font-size: 10px;
      text-align: center;
    }
  }
}

/* Small (sm) */
@media (min-width: 600px) and (max-width: 959px) {
  /* CSS rules for small devices */
}

/* Medium (md) */
@media (min-width: 960px) and (max-width: 1263px) {
  /* CSS rules for medium devices */
}

/* Large (lg) */
@media (min-width: 1264px) and (max-width: 1903px) {
  /* CSS rules for large devices */
}

/* Extra large (xl) */
@media (min-width: 1904px) {
  /* CSS rules for extra large devices */
}

.hover-effect {
  transition: background-color 0.3s ease;
}
.hover-effect:hover {
  background-color: #fafafa;
  cursor: pointer;
}
</style>
